/** @jsx jsx */
import { useEffect, useMemo } from 'react'
import { navigate } from 'gatsby'
import { StytchLogin, StytchProvider } from '@stytch/nextjs'
import { createStytchUIClient } from '@stytch/nextjs/ui'
import { jsx } from 'theme-ui'
import { useAuth, utils } from '@chordcommerce/gatsby-theme-autonomy'

const AuthLoginForm = () => {
  const { getToken, isLoggedIn, serviceClient } = useAuth()
  const { getParam } = utils

  const baseUrl = typeof window !== 'undefined' && window.location.origin
  const destination = getParam('destination') || '/'
  const fullRedirectUrl = `${baseUrl}/authenticate/?destination=${destination}`

  useEffect(() => {
    if (isLoggedIn) {
      navigate(destination)
    }
  }, [isLoggedIn, destination])

  const stytchProps = useMemo(
    () => ({
      // @see https://stytch.com/docs/sdks/javascript-sdk#ui-config
      config: {
        products: ['emailMagicLinks', 'oauth'],
        emailMagicLinksOptions: {
          loginRedirectURL: fullRedirectUrl,
          loginExpirationMinutes: 60,
          signupRedirectURL: fullRedirectUrl,
          signupExpirationMinutes: 60,
        },
        oauthOptions: {
          loginRedirectURL: fullRedirectUrl,
          signupRedirectURL: fullRedirectUrl,
          providers: [{ type: 'google' }],
        },
      },
      styles: {
        fontFamily: 'FF DIN Pro',
        hideHeaderText: true,
        container: {
          borderColor: '#F0F0F1',
          borderRadius: '8px',
          width: '100%',
        },
        buttons: {
          primary: {
            backgroundColor: '#2A679E',
            borderColor: 'transparent',
            textColor: '#FFFFFF',
          },
        },
      },
      callbacks: {
        onEvent: async () => {
          const intervalID = setInterval(async () => {
            if (serviceClient?.session.getSync()) {
              await getToken()
              clearInterval(intervalID)
              navigate('/')
            }
          }, 1000)
        },
        onError: (message) => {
          window.analytics.track({
            message,
            meta: {
              storeId: process.env.GATSBY_CHORD_OMS_STORE_ID,
              omsId: process.env.GATSBY_CHORD_OMS_ID,
              tenantId: process.env.GATSBY_CHORD_OMS_TENANT_ID,
              currency: 'USD',
              locale: 'en-US',
            },
          })
        },
      },
    }),
    [fullRedirectUrl, getToken, serviceClient]
  )

  return (
    <StytchProvider
      stytch={createStytchUIClient(process.env.GATSBY_PUBLIC_STYTCH_API_KEY)}
    >
      <StytchLogin
        config={stytchProps.config}
        styles={stytchProps.styles}
        callbacks={stytchProps.callbacks}
      />
    </StytchProvider>
  )
}

export default AuthLoginForm
