/** @jsx jsx */
import { Fragment } from 'react'
import { Link } from 'gatsby'
import { Box, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import { utils } from '@chordcommerce/gatsby-theme-autonomy'
import AuthLoginForm from '~/components/Auth/Login/Form'
import VisuallyHidden from '~/components/Generic/VisuallyHidden'

const AccountLoginPage = () => {
  const { getParam } = utils

  const redeemEmailAddress = getParam('redeem')
  const isRedeeming = !!redeemEmailAddress

  return (
    <Box>
      <Container>
        <Grid columns={[1, 12]}>
          <Box
            pt={[6, 7, 9]}
            pb={[4, 8, 10]}
            sx={{ gridColumn: [null, '1 / 8', '1 / 7', '1 / 6'] }}
          >
            <Heading as="h1" variant="title2" mb={1}>
              {!isRedeeming ? 'Log in' : 'Redeem store credit'}
            </Heading>
            <Text as="p" variant="body3">
              {!isRedeeming && (
                <Fragment>
                  Log in to access your order history and manage your account
                  settings. <Link to="/can-club/">Can Club</Link> members can
                  also manage their Club shipments and join exclusive events and
                  tastings.
                </Fragment>
              )}
              {isRedeeming && (
                <Fragment>
                  Log in to redeem your store credit. You'll also be able to
                  access your order history and manage your account settings.{' '}
                  <Link to="/can-club/">Can Club</Link> members can also manage
                  their Club shipments and join exclusive events and tastings.
                </Fragment>
              )}
            </Text>
            <Box mt={4}>
              <VisuallyHidden>
                <Heading as="h2" variant="title3" mb={1}>
                  {'Log in form'}
                </Heading>
                <Text as="p" variant="body3" mb={2}>
                  Provide your email address in the form below to log in.
                </Text>
              </VisuallyHidden>
              <AuthLoginForm />
            </Box>
          </Box>
          <Box
            bg="backgroundMedium"
            ml={[-2, 0]}
            mr={[-2, -3, null, 0]}
            pt={[4, 7, 9]}
            pb={[7, 8, 10]}
            px={[2, 3]}
            sx={{ gridColumn: [null, '8 / -1', null, '7 / -1'] }}
          >
            <Grid columns={[null, null, null, 6]}>
              <Box pt={2} sx={{ gridColumn: [null, null, null, '1 / 6'] }}>
                <Box mb={4}>
                  <Heading as="h2" variant="title5" mb={1}>
                    {'What about my password?'}
                  </Heading>
                  <Text as="p" variant="body3">
                    We don't believe in passwords. Enter your email address and
                    we'll email you a magic link. Click that link from any
                    device and you'll be logged in!
                  </Text>
                </Box>
                <Box mb={4}>
                  <Heading as="h2" variant="title5" mb={1}>
                    {"Don't have an account?"}
                  </Heading>
                  <Text as="p" variant="body3">
                    No problem! Provide your email address in the log in form,
                    click the magic link that we'll send to your email, and
                    you'll be logged in to your brand new account!
                  </Text>
                </Box>
                <Box mb={4}>
                  <Heading as="h2" variant="title5" mb={1}>
                    {'Have another question?'}
                  </Heading>
                  <Text as="p" variant="body3">
                    Don't hesitate to reach out! Email us at{' '}
                    <a href="mailto:support@makerwine.com">
                      support@makerwine.com
                    </a>
                    .
                  </Text>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default AccountLoginPage
