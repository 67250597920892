import React from 'react'
import Layout from '~/components/Layout'
import LoginPage from '~/components/Auth/Login/Page'
import Metadata from '~/components/Metadata'

const Login = () => {
  return (
    <Layout>
      <Metadata
        title="Account"
        description="Log in to your Maker Wine account."
      />
      <LoginPage />
    </Layout>
  )
}

export default Login
